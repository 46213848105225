var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"-vdu_nkKRFQ8WhHOATKBm"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"

// Sentry.init({
//   dsn: "https://39824297fdaaaa06ab62a4d077cdfc8b@o4506599397588992.ingest.us.sentry.io/4506599400407040",
//   integrations: [Sentry.replayIntegration()],
//   tracesSampleRate: 1.0,
//   // replaysSessionSampleRate: 0.1,
//   // replaysOnErrorSampleRate: 1.0,
// })
