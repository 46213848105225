export const languageCodeToCountryCode = {
  AA: "ET",
  AB: "GE",
  ABR: "GH",
  ACE: "ID",
  ACH: "UG",
  ADA: "GH",
  ADY: "RU",
  AE: "IR",
  AEB: "TN",
  AF: "ZA",
  AGQ: "CM",
  AHO: "IN",
  AK: "GH",
  AKK: "IQ",
  ALN: "XK",
  ALT: "RU",
  AM: "ET",
  AMO: "NG",
  AOZ: "ID",
  APD: "TG",
  AR: "EG",
  ARC: "IR",
  "ARC-NBAT": "JO",
  "ARC-PALM": "SY",
  ARN: "CL",
  ARO: "BO",
  ARQ: "DZ",
  ARY: "MA",
  ARZ: "EG",
  AS: "IN",
  ASA: "TZ",
  ASE: "US",
  AST: "ES",
  ATJ: "CA",
  AV: "RU",
  AWA: "IN",
  AY: "BO",
  AZ: "AZ",
  "AZ-ARAB": "IR",
  BA: "RU",
  BAL: "PK",
  BAN: "ID",
  BAP: "NP",
  BAR: "AT",
  BAS: "CM",
  BAX: "CM",
  BBC: "ID",
  BBJ: "CM",
  BCI: "CI",
  BE: "BY",
  BEJ: "SD",
  BEM: "ZM",
  BEW: "ID",
  BEZ: "TZ",
  BFD: "CM",
  BFQ: "IN",
  BFT: "PK",
  BFY: "IN",
  BG: "BG",
  BGC: "IN",
  BGN: "PK",
  BGX: "TR",
  BHB: "IN",
  BHI: "IN",
  BHK: "PH",
  BHO: "IN",
  BI: "VU",
  BIK: "PH",
  BIN: "NG",
  BJJ: "IN",
  BJN: "ID",
  BJT: "SN",
  BKM: "CM",
  BKU: "PH",
  BLT: "VN",
  BM: "ML",
  BMQ: "ML",
  BN: "BD",
  BO: "CN",
  BPY: "IN",
  BQI: "IR",
  BQV: "CI",
  BR: "FR",
  BRA: "IN",
  BRH: "PK",
  BRX: "IN",
  BS: "BA",
  BSQ: "LR",
  BSS: "CM",
  BTO: "PH",
  BTV: "PK",
  BUA: "RU",
  BUC: "YT",
  BUG: "ID",
  BUM: "CM",
  BVB: "GQ",
  BYN: "ER",
  BYV: "CM",
  BZE: "ML",
  CA: "ES",
  CCH: "NG",
  CCP: "BD",
  CE: "RU",
  CEB: "PH",
  CGG: "UG",
  CH: "GU",
  CHK: "FM",
  CHM: "RU",
  CHO: "US",
  CHP: "CA",
  CHR: "US",
  CJA: "KH",
  CJM: "VN",
  CKB: "IQ",
  CO: "FR",
  COP: "EG",
  CPS: "PH",
  CR: "CA",
  CRH: "UA",
  CRJ: "CA",
  CRK: "CA",
  CRL: "CA",
  CRM: "CA",
  CRS: "SC",
  CS: "CZ",
  CSB: "PL",
  CSW: "CA",
  CTD: "MM",
  CU: "RU",
  "CU-GLAG": "BG",
  CV: "RU",
  CY: "GB",
  DA: "DK",
  DAK: "US",
  DAR: "RU",
  DAV: "KE",
  DCC: "IN",
  DE: "DE",
  DEN: "CA",
  DGR: "CA",
  DJE: "NE",
  DNJ: "CI",
  DOI: "IN",
  DSB: "DE",
  DTM: "ML",
  DTP: "MY",
  DTY: "NP",
  DUA: "CM",
  DV: "MV",
  DYO: "SN",
  DYU: "BF",
  DZ: "BT",
  EBU: "KE",
  EE: "GH",
  EFI: "NG",
  EGL: "IT",
  EGY: "EG",
  EKY: "MM",
  EL: "GR",
  EN: "US",
  "EN-SHAW": "GB",
  ES: "ES",
  ESU: "US",
  ET: "EE",
  ETT: "IT",
  EU: "ES",
  EWO: "CM",
  EXT: "ES",
  FA: "IR",
  FAN: "GQ",
  FF: "SN",
  "FF-ADLM": "GN",
  FFM: "ML",
  FI: "FI",
  FIA: "SD",
  FIL: "PH",
  FIT: "SE",
  FJ: "FJ",
  FO: "FO",
  FON: "BJ",
  FR: "FR",
  FRC: "US",
  FRP: "FR",
  FRR: "DE",
  FRS: "DE",
  FUB: "CM",
  FUD: "WF",
  FUF: "GN",
  FUQ: "NE",
  FUR: "IT",
  FUV: "NG",
  FVR: "SD",
  FY: "NL",
  GA: "IE",
  GAA: "GH",
  GAG: "MD",
  GAN: "CN",
  GAY: "ID",
  GBM: "IN",
  GBZ: "IR",
  GCR: "GF",
  GD: "GB",
  GEZ: "ET",
  GGN: "NP",
  GIL: "KI",
  GJK: "PK",
  GJU: "PK",
  GL: "ES",
  GLK: "IR",
  GN: "PY",
  GOM: "IN",
  GON: "IN",
  GOR: "ID",
  GOS: "NL",
  GOT: "UA",
  GRC: "CY",
  "GRC-LINB": "GR",
  GRT: "IN",
  GSW: "CH",
  GU: "IN",
  GUB: "BR",
  GUC: "CO",
  GUR: "GH",
  GUZ: "KE",
  GV: "IM",
  GVR: "NP",
  GWI: "CA",
  HA: "NG",
  HAK: "CN",
  HAW: "US",
  HAZ: "AF",
  HE: "IL",
  HI: "IN",
  HIF: "FJ",
  HIL: "PH",
  HLU: "TR",
  HMD: "CN",
  HND: "PK",
  HNE: "IN",
  HNJ: "LA",
  HNN: "PH",
  HNO: "PK",
  HO: "PG",
  HOC: "IN",
  HOJ: "IN",
  HR: "HR",
  HSB: "DE",
  HSN: "CN",
  HT: "HT",
  HU: "HU",
  HY: "AM",
  HZ: "NA",
  IA: "FR",
  IBA: "MY",
  IBB: "NG",
  ID: "ID",
  IFE: "TG",
  IG: "NG",
  II: "CN",
  IK: "US",
  IKT: "CA",
  ILO: "PH",
  IN: "ID",
  INH: "RU",
  IS: "IS",
  IT: "IT",
  IU: "CA",
  IW: "IL",
  IZH: "RU",
  JA: "JP",
  JAM: "JM",
  JGO: "CM",
  JI: "UA",
  JMC: "TZ",
  JML: "NP",
  JUT: "DK",
  JV: "ID",
  JW: "ID",
  KA: "GE",
  KAA: "UZ",
  KAB: "DZ",
  KAC: "MM",
  KAJ: "NG",
  KAM: "KE",
  KAO: "ML",
  KBD: "RU",
  KBY: "NE",
  KCG: "NG",
  KCK: "ZW",
  KDE: "TZ",
  KDH: "TG",
  KDT: "TH",
  KEA: "CV",
  KEN: "CM",
  KFO: "CI",
  KFR: "IN",
  KFY: "IN",
  KG: "CD",
  KGE: "ID",
  KGP: "BR",
  KHA: "IN",
  KHB: "CN",
  KHN: "IN",
  KHQ: "ML",
  KHT: "IN",
  KHW: "PK",
  KI: "KE",
  KIU: "TR",
  KJ: "NA",
  KJG: "LA",
  KK: "KZ",
  "KK-ARAB": "CN",
  KKJ: "CM",
  KL: "GL",
  KLN: "KE",
  KM: "KH",
  KMB: "AO",
  KN: "IN",
  KNF: "SN",
  KO: "KR",
  KOI: "RU",
  KOK: "IN",
  KOS: "FM",
  KPE: "LR",
  KRC: "RU",
  KRI: "SL",
  KRJ: "PH",
  KRL: "RU",
  KRU: "IN",
  KS: "IN",
  KSB: "TZ",
  KSF: "CM",
  KSH: "DE",
  KU: "TR",
  "KU-ARAB": "IQ",
  KUM: "RU",
  KV: "RU",
  KVR: "ID",
  KVX: "PK",
  KW: "GB",
  KXM: "TH",
  KXP: "PK",
  KY: "KG",
  "KY-ARAB": "CN",
  "KY-LATN": "TR",
  LA: "VA",
  LAB: "GR",
  LAD: "IL",
  LAG: "TZ",
  LAH: "PK",
  LAJ: "UG",
  LB: "LU",
  LBE: "RU",
  LBW: "ID",
  LCP: "CN",
  LEP: "IN",
  LEZ: "RU",
  LG: "UG",
  LI: "NL",
  LIF: "NP",
  "LIF-LIMB": "IN",
  LIJ: "IT",
  LIS: "CN",
  LJP: "ID",
  LKI: "IR",
  LKT: "US",
  LMN: "IN",
  LMO: "IT",
  LN: "CD",
  LO: "LA",
  LOL: "CD",
  LOZ: "ZM",
  LRC: "IR",
  LT: "LT",
  LTG: "LV",
  LU: "CD",
  LUA: "CD",
  LUO: "KE",
  LUY: "KE",
  LUZ: "IR",
  LV: "LV",
  LWL: "TH",
  LZH: "CN",
  LZZ: "TR",
  MAD: "ID",
  MAF: "CM",
  MAG: "IN",
  MAI: "IN",
  MAK: "ID",
  MAN: "GM",
  "MAN-NKOO": "GN",
  MAS: "KE",
  MAZ: "MX",
  MDF: "RU",
  MDH: "PH",
  MDR: "ID",
  MEN: "SL",
  MER: "KE",
  MFA: "TH",
  MFE: "MU",
  MG: "MG",
  MGH: "MZ",
  MGO: "CM",
  MGP: "NP",
  MGY: "TZ",
  MH: "MH",
  MI: "NZ",
  MIN: "ID",
  MIS: "IQ",
  MK: "MK",
  ML: "IN",
  MLS: "SD",
  MN: "MN",
  "MN-MONG": "CN",
  MNI: "IN",
  MNW: "MM",
  MOE: "CA",
  MOH: "CA",
  MOS: "BF",
  MR: "IN",
  MRD: "NP",
  MRJ: "RU",
  MRO: "BD",
  MS: "MY",
  MT: "MT",
  MTR: "IN",
  MUA: "CM",
  MUS: "US",
  MVY: "PK",
  MWK: "ML",
  MWR: "IN",
  MWV: "ID",
  MXC: "ZW",
  MY: "MM",
  MYV: "RU",
  MYX: "UG",
  MYZ: "IR",
  MZN: "IR",
  NA: "NR",
  NAN: "CN",
  NAP: "IT",
  NAQ: "NA",
  NB: "NO",
  NCH: "MX",
  ND: "ZW",
  NDC: "MZ",
  NDS: "DE",
  NE: "NP",
  NEW: "NP",
  NG: "NA",
  NGL: "MZ",
  NHE: "MX",
  NHW: "MX",
  NIJ: "ID",
  NIU: "NU",
  NJO: "IN",
  NL: "NL",
  NMG: "CM",
  NN: "NO",
  NNH: "CM",
  NO: "NO",
  NOD: "TH",
  NOE: "IN",
  NON: "SE",
  NQO: "GN",
  NR: "ZA",
  NSK: "CA",
  NSO: "ZA",
  NUS: "SS",
  NV: "US",
  NXQ: "CN",
  NY: "MW",
  NYM: "TZ",
  NYN: "UG",
  NZI: "GH",
  OC: "FR",
  OM: "ET",
  OR: "IN",
  OS: "GE",
  OSA: "US",
  OTK: "MN",
  PA: "IN",
  "PA-ARAB": "PK",
  PAG: "PH",
  PAL: "IR",
  "PAL-PHLP": "CN",
  PAM: "PH",
  PAP: "AW",
  PAU: "PW",
  PCD: "FR",
  PCM: "NG",
  PDC: "US",
  PDT: "CA",
  PEO: "IR",
  PFL: "DE",
  PHN: "LB",
  PKA: "IN",
  PKO: "KE",
  PL: "PL",
  PMS: "IT",
  PNT: "GR",
  PON: "FM",
  PRA: "PK",
  PRD: "IR",
  PS: "AF",
  PT: "BR",
  PUU: "GA",
  QU: "PE",
  QUC: "GT",
  QUG: "EC",
  RAJ: "IN",
  RCF: "RE",
  REJ: "ID",
  RGN: "IT",
  RIA: "IN",
  RIF: "MA",
  RJS: "NP",
  RKT: "BD",
  RM: "CH",
  RMF: "FI",
  RMO: "CH",
  RMT: "IR",
  RMU: "SE",
  RN: "BI",
  RNG: "MZ",
  RO: "RO",
  ROB: "ID",
  ROF: "TZ",
  RTM: "FJ",
  RU: "RU",
  RUE: "UA",
  RUG: "SB",
  RW: "RW",
  RWK: "TZ",
  RYU: "JP",
  SA: "IN",
  SAF: "GH",
  SAH: "RU",
  SAQ: "KE",
  SAS: "ID",
  SAT: "IN",
  SAV: "SN",
  SAZ: "IN",
  SBP: "TZ",
  SC: "IT",
  SCK: "IN",
  SCN: "IT",
  SCO: "GB",
  SCS: "CA",
  SD: "PK",
  "SD-DEVA": "IN",
  "SD-KHOJ": "IN",
  "SD-SIND": "IN",
  SDC: "IT",
  SDH: "IR",
  SE: "NO",
  SEF: "CI",
  SEH: "MZ",
  SEI: "MX",
  SES: "ML",
  SG: "CF",
  SGA: "IE",
  SGS: "LT",
  SHI: "MA",
  SHN: "MM",
  SI: "LK",
  SID: "ET",
  SK: "SK",
  SKR: "PK",
  SL: "SI",
  SLI: "PL",
  SLY: "ID",
  SM: "WS",
  SMA: "SE",
  SMJ: "SE",
  SMN: "FI",
  SMP: "IL",
  SMS: "FI",
  SN: "ZW",
  SNK: "ML",
  SO: "SO",
  SOU: "TH",
  SQ: "AL",
  SR: "RS",
  SRB: "IN",
  SRN: "SR",
  SRR: "SN",
  SRX: "IN",
  SS: "ZA",
  SSY: "ER",
  ST: "ZA",
  STQ: "DE",
  SU: "ID",
  SUK: "TZ",
  SUS: "GN",
  SV: "SE",
  SW: "TZ",
  SWB: "YT",
  SWC: "CD",
  SWG: "DE",
  SWV: "IN",
  SXN: "ID",
  SYL: "BD",
  SYR: "IQ",
  SZL: "PL",
  TA: "IN",
  TAJ: "NP",
  TBW: "PH",
  TCY: "IN",
  TDD: "CN",
  TDG: "NP",
  TDH: "NP",
  TE: "IN",
  TEM: "SL",
  TEO: "UG",
  TET: "TL",
  TG: "TJ",
  "TG-ARAB": "PK",
  TH: "TH",
  THL: "NP",
  THQ: "NP",
  THR: "NP",
  TI: "ET",
  TIG: "ER",
  TIV: "NG",
  TK: "TM",
  TKL: "TK",
  TKR: "AZ",
  TKT: "NP",
  TL: "PH",
  TLY: "AZ",
  TMH: "NE",
  TN: "ZA",
  TO: "TO",
  TOG: "MW",
  TPI: "PG",
  TR: "TR",
  TRU: "TR",
  TRV: "TW",
  TS: "ZA",
  TSD: "GR",
  TSF: "NP",
  TSG: "PH",
  TSJ: "BT",
  TT: "RU",
  TTJ: "UG",
  TTS: "TH",
  TTT: "AZ",
  TUM: "MW",
  TVL: "TV",
  TWQ: "NE",
  TXG: "CN",
  TY: "PF",
  TYV: "RU",
  TZM: "MA",
  UDM: "RU",
  UG: "CN",
  "UG-CYRL": "KZ",
  UGA: "SY",
  UK: "UA",
  ULI: "FM",
  UMB: "AO",
  UND: "US",
  UNR: "IN",
  "UNR-DEVA": "NP",
  UNX: "IN",
  UR: "PK",
  UZ: "UZ",
  "UZ-ARAB": "AF",
  VAI: "LR",
  VE: "ZA",
  VEC: "IT",
  VEP: "RU",
  VI: "VN",
  VIC: "SX",
  VLS: "BE",
  VMF: "DE",
  VMW: "MZ",
  VOT: "RU",
  VRO: "EE",
  VUN: "TZ",
  WA: "BE",
  WAE: "CH",
  WAL: "ET",
  WAR: "PH",
  WBP: "AU",
  WBQ: "IN",
  WBR: "IN",
  WLS: "WF",
  WNI: "KM",
  WO: "SN",
  WTM: "IN",
  WUU: "CN",
  XAV: "BR",
  XCR: "TR",
  XH: "ZA",
  XLC: "TR",
  XLD: "TR",
  XMF: "GE",
  XMN: "CN",
  XMR: "SD",
  XNA: "SA",
  XNR: "IN",
  XOG: "UG",
  XPR: "IR",
  XSA: "YE",
  XSR: "NP",
  YAO: "MZ",
  YAP: "FM",
  YAV: "CM",
  YBB: "CM",
  YO: "NG",
  YRL: "BR",
  YUA: "MX",
  YUE: "HK",
  "YUE-HANS": "CN",
  ZA: "CN",
  ZAG: "SD",
  ZDJ: "KM",
  ZEA: "NL",
  ZGH: "MA",
  ZH: "CN",
  "ZH-BOPO": "TW",
  "ZH-HANB": "TW",
  "ZH-HANT": "TW",
  ZLM: "TG",
  ZMI: "MY",
  ZU: "ZA",
  ZZA: "TR",
}
